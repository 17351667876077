import React from 'react';
import Layout from '../Layout/Layout';
import cardo from "../image/cardio.jpg"
import neuro from "../image/neuro.jpg"
import gyne from "../image/Gyne.jpg"
import ortho from "../image/ortho.jpg"
const treatments = [
  {
    title: 'Cardiology',
    description: 'Comprehensive cardiac care including diagnostics, treatment, and rehabilitation for heart diseases.',
    image:cardo,
  },
  {
    title: 'Orthopedics',
    description: 'Advanced orthopedic services including joint replacement, fracture management, and sports medicine.',
    image:ortho,
  },
  {
    title: 'Neurology',
    description: 'Expert care for neurological conditions such as stroke, epilepsy, and Parkinson’s disease.',
    image:neuro,
  },
 
  {
    title: 'Gynecology',
    description: 'Comprehensive gynecological care including prenatal and postnatal care, and minimally invasive surgeries.',
    image:gyne,
  },
];

const Treatment = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center  py-12 mx-auto container">
        <h1 className="text-5xl font-extrabold text-gray-800 mb-8">Our Treatments</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {treatments.map((treatment, index) => (
            <div key={index} className="bg-white shadow-lg rounded-lg p-6">
              <img src={treatment.image} alt={treatment.title} className="rounded-lg mb-4" />
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">{treatment.title}</h2>
              <p className="text-lg text-gray-700">{treatment.description}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Treatment;
