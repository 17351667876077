import React from 'react';
import Layout from '../Layout/Layout';
import doctor from "../image/doctor.jpeg"
const AboutUs = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center bg-gray-100 py-12">
        <h1 className="text-5xl font-extrabold text-gray-800 mb-8">About Us</h1>
        <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl">
          <h2 className="text-3xl font-semibold mb-6 text-gray-800">National Hospital, Sagar</h2>
          <p className="text-lg text-gray-700 mb-4">
            National Hospital, Sagar was founded by Dr. Virendra Yadav, MD, a highly esteemed medical professional and the previous Regional Director of Health, Sagar. Dr. Yadav's vision was to create a healthcare institution that provides exceptional medical services and ensures the well-being of the community.
          </p>
          <p className="text-lg text-gray-700 mb-4">
            With a team of experienced doctors, nurses, and healthcare professionals, National Hospital is dedicated to offering a wide range of medical services, from general healthcare to specialized treatments. Our mission is to deliver comprehensive, patient-centered care in a welcoming and supportive environment.
          </p>
          <p className="text-lg text-gray-700 mb-4">
            At National Hospital, we prioritize the health and comfort of our patients. We are equipped with state-of-the-art medical technology and facilities to provide accurate diagnoses and effective treatments. Our commitment to excellence and patient satisfaction is unwavering.
          </p>
          <div className="flex flex-col sm:flex-row sm:justify-between mt-8">
            <div className="flex flex-col items-center mb-8 sm:mb-0">
              <img
                src={doctor}
                alt="Dr. Virendra Yadav"
                className="rounded-full mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-800">Dr. Virendra Yadav, MD</h3>
              <p className="text-gray-600">Founder & Former Regional Director of Health, Sagar</p>
            </div>
            <div className="sm:max-w-sm">
              <p className="text-lg text-gray-700">
                Dr. Virendra Yadav is a renowned medical professional with extensive experience in healthcare management and patient care. Under his leadership, National Hospital has grown to become a trusted name in the healthcare industry, known for its compassionate care and advanced medical services.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
