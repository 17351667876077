import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import logo from '../image/logo.jpeg'; // Adjust the path to your logo
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-blue-800 text-white shadow-sm">
      <div className="container mx-auto flex flex-row justify-between items-center py-4 px-6">
        <img src={logo} alt="National Hospital Logo" className="h-10" />
        <nav className="hidden md:flex space-x-4">
          <Link to="/" className="hover:text-gray-300 text-lg">Home</Link>
          <Link to="/aboutus" className="hover:text-gray-300 text-lg">About</Link>
          <Link to="/treatments" className="hover:text-gray-300 text-lg">Treatments</Link>
          <Link to="/services" className="hover:text-gray-300 text-lg">Services</Link>
          <Link to="/contactus" className="hover:text-gray-300 text-lg">Contact Us</Link>
        </nav>
        <div className="flex items-center md:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
        <div className="hidden md:flex items-center space-x-4">
          <a href="#search" className="text-white hover:text-gray-300">
            <AiOutlineSearch className="h-6 w-6" />
          </a>
        </div>
      </div>
      <div className={`fixed inset-0 bg-blue-800 bg-opacity-90 z-50 transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <div className="flex flex-col items-center justify-center h-full">
          <Link to="/" className="text-white text-xl mb-4 hover:text-gray-300" onClick={toggleMenu}>Home</Link>
          <Link to="/aboutus" className="text-white text-xl mb-4 hover:text-gray-300" onClick={toggleMenu}>About</Link>
          <Link to="/treatments" className="text-white text-xl mb-4 hover:text-gray-300" onClick={toggleMenu}>Treatments</Link>
          <Link to="/services" className="text-white text-xl mb-4 hover:text-gray-300" onClick={toggleMenu}>Services</Link>
          <Link to="/contactus" className="text-white text-xl hover:text-gray-300" onClick={toggleMenu}>Contact Us</Link>
          <a href="#search" className="text-white text-xl mt-4 hover:text-gray-300" onClick={toggleMenu}>
            <AiOutlineSearch className="h-6 w-6" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
