import React from 'react';
import Header from '../Layout/Header';
import ImageOne from "../image/b1.jpeg"
import ImageTwo from "../image/b2.jpeg"
import ImageThree from "../image/b3.jpeg"
import Slideshow from '../components/SlideShow';
import cardo from "../image/cardio.jpg"
import neuro from "../image/neuro.jpg"
import gyne from "../image/Gyne.jpg"
import ortho from "../image/ortho.jpg"
import Layout from '../Layout/Layout';
// Sample data for hero slides and departments
const heroSlides = [
  { id: 1, title: 'Welcome to National Hospital', subtitle: 'Providing Quality Healthcare Since 2024', img:ImageOne },
  { id: 2, title: 'Comprehensive Medical Services', subtitle: 'Advanced and Compassionate Care', img: ImageTwo },
  { id: 3, title: 'Expert Doctors and Specialists', subtitle: 'Your Health is Our Priority', img:ImageThree },
];

const departments = [
  { id: 1, name: 'Cardiology', description: 'Advanced heart care and treatments.', img:cardo },
  { id: 2, name: 'Neurology', description: 'Comprehensive neurological services.', img:neuro },
  { id: 3, name: 'Gynecology', description: 'Specialized care for women\'s reproductive health.', img: gyne },
  { id: 4, name: 'Orthopedics', description: 'Expert care for bones, joints, and muscles.', img:ortho },
];

const HomePage = () => {
  return (
     <Layout>
         <div className="font-sans leading-normal tracking-normal">
      {/* Header */}
       
      

      {/* Hero Section */}
      <Slideshow slides={heroSlides} />

      {/* Department Section */}
      <section className="py-2 bg-white">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-8">Our Departments</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {departments.map(department => (
              <div key={department.id} className="bg-gray-100 rounded-lg overflow-hidden shadow-lg">
                <img src={department.img} alt={department.name} className="w-full h-48 object-cover" />
                <div className="p-6">
                  <h3 className="text-2xl font-bold mb-2">{department.name}</h3>
                  <p className="text-gray-700">{department.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

     
     
    </div>
     </Layout>
  );
};

export default HomePage;
