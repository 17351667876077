import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const Slideshow = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, [slides.length]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  return (
    <section className="relative h-dvh sm:h-screen bg-gray-100 overflow-hidden">
      <div className="absolute inset-0">
        <div className="relative h-full">
          {slides.map((slide, index) => (
            <div
              key={slide.id}
              className={classNames(
                'absolute inset-0 transition-opacity duration-1000',
                {
                  'opacity-100': index === currentSlide,
                  'opacity-0': index !== currentSlide,
                }
              )}
            >
              <img
                src={slide.img}
                alt={slide.title}
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="text-center text-white">
                  <h1 className="text-5xl font-bold">{slide.title}</h1>
                  <p className="text-xl mt-4">{slide.subtitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-10 flex justify-center space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={classNames(
              'w-3 h-3 rounded-full bg-white',
              {
                'opacity-75': index === currentSlide,
                'opacity-25': index !== currentSlide,
              }
            )}
          ></button>
        ))}
      </div>
      <div className="absolute inset-y-0 left-0 flex items-center">
        <button
          onClick={prevSlide}
          className="p-3 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full"
        >
          &#9664;
        </button>
      </div>
      <div className="absolute inset-y-0 right-0 flex items-center">
        <button
          onClick={nextSlide}
          className="p-3 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full"
        >
          &#9654;
        </button>
      </div>
    </section>
  );
};

export default Slideshow;
