// ContactUs.js
import React from 'react';
import Layout from '../Layout/Layout';

const ContactUs = () => {
  return (
      <Layout>
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-600 py-8">
      <h1 className="text-5xl font-extrabold text-white mb-8">Contact Us</h1>
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-lg">
        <h2 className="text-3xl font-semibold mb-6 text-gray-800">National Hospital</h2>
        <div className="text-lg text-gray-700 mb-4">
          <p className="flex items-center mb-2">
            <svg className="w-6 h-6 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 8c1.656 0 3 1.344 3 3s-1.344 3-3 3c-.552 0-1-.448-1-1s.448-1 1-1c.552 0 1-.448 1-1s-.448-1-1-1c-.552 0-1-.448-1-1s.448-1 1-1M16 4v3m0 2v3m0 3v2M8 8c-1.656 0-3 1.344-3 3s1.344 3 3 3c.552 0 1 .448 1 1s-.448 1-1 1c-.552 0-1 .448-1 1s.448 1 1 1c1.656 0 3-1.344 3-3s-1.344-3-3-3c-.552 0-1-.448-1-1s.448-1 1-1c.552 0 1-.448 1-1s-.448-1-1-1z" />
            </svg>
            Address: Tili Road, Near BMC, Sagar, MP
          </p>
          <p className="flex items-center mb-2">
            <svg className="w-6 h-6 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5z" />
            </svg>
            Phone: 6263199306
          </p>
          <p className="flex items-center mb-2">
            <svg className="w-6 h-6 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12h2m-6-6h.01M12 12v10m4-4H8m12 0a9.969 9.969 0 01-1.513 5.071A9.969 9.969 0 0112 22a9.969 9.969 0 01-5.071-1.513A9.969 9.969 0 015.5 17.5c.007-5.523 4.48-9.994 9.993-9.993a9.969 9.969 0 015.072 1.513A9.969 9.969 0 0120 12v0z" />
            </svg>
            Email: hello.nationalhospital@gmail.com
          </p>
        </div>
        <div className="flex justify-center">
          <button className="bg-blue-500 text-white py-2 px-4 rounded-full shadow-md hover:bg-blue-600 transition duration-300">
            Send us a message
          </button>
        </div>
      </div>
    </div>
      </Layout>
  );
};

export default ContactUs;
