import React from 'react';
import Layout from '../Layout/Layout';

const FreeMedicalCamp = () => {
  return (
    <Layout>
   <div className="min-h-screen bg-gradient-to-r from-green-400 to-blue-500 flex items-center justify-center  px-4 sm:px-6 lg:px-8">
      <div className="max-w-xl w-full space-y-2">
        <div className="text-center">
          <h1 className=" text-xl sm:text-4xl font-extrabold text-white drop-shadow-lg">
            🌟 Free Medical Camp 🌟
          </h1>
          <p className="mt-2 text-lg text-white font-medium">
            Brought to you by Instamedic & National Hospital
          </p>
        </div>
        <div className="bg-white shadow-2xl rounded-lg p-8">
          <h2 className="text-2xl font-semibold text-gray-800">
            Join Us for a Day of Health & Wellness!
          </h2>
          <p className="mt-4 text-gray-700">
            We are thrilled to invite you to our Free Medical Camp on <span className="font-bold text-green-600">14th July 2024</span> at <span className="font-bold text-green-600">National Hospital, Tili Road, Sagar, Madhya Pradesh</span>.
          </p>
          <div className="mt-6 space-y-4">
            <div className="flex items-start">
              <span className="flex-shrink-0 h-6 w-6 text-green-500">
                ✔️
              </span>
              <p className="ml-2 text-gray-700">Specialist Teleconsultations</p>
            </div>
            <div className="flex items-start">
              <span className="flex-shrink-0 h-6 w-6 text-green-500">
                ✔️
              </span>
              <p className="ml-2 text-gray-700">General Health Check-ups</p>
            </div>
            <div className="flex items-start hidden">
              <span className="flex-shrink-0 h-6 w-6 text-green-500">
                ✔️
              </span>
              <p className="ml-2 text-gray-700">Free Medicines</p>
            </div>
            <div className="flex items-start">
              <span className="flex-shrink-0 h-6 w-6 text-green-500">
                ✔️
              </span>
              <p className="ml-2 text-gray-700">Expert Health Advice</p>
            </div>
          </div>
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-800">
              Event Details:
            </h3>
            <p className="mt-2 text-gray-700">
              📅 <span className="font-semibold">Date:</span> 14th July 2024<br />
              🕘 <span className="font-semibold">Time:</span> 9:00 AM - 5:00 PM<br />
              📍 <span className="font-semibold">Location:</span> National Hospital, Tili Road, Sagar, Madhya Pradesh
            </p>
          </div>
          <div className="mt-8 text-center">
            <p className="text-gray-700">
              For more information, contact us at <span className="font-bold text-green-600">9098762631</span>.
            </p>
          </div>
          <div className="mt-6 text-center">
            <p className="text-green-600 font-bold text-lg">
              Together, let's ignite the spark of better health!
            </p>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default FreeMedicalCamp;
