import React from 'react';
import Layout from '../Layout/Layout';
import logo from "../image/logo.jpeg"
const services = [
  {
    title: 'Emergency Services',
    description: '24/7 emergency services with immediate medical attention for critical cases.',
    icon: logo,
  },
  {
    title: 'Outpatient Services',
    description: 'Comprehensive outpatient services for routine check-ups and minor procedures.',
    icon: logo,
  },
  {
    title: 'Inpatient Services',
    description: 'Comfortable inpatient facilities with dedicated care for patients requiring hospitalization.',
    icon: logo,
  },
  {
    title: 'Laboratory Services',
    description: 'Advanced laboratory services for accurate diagnostics and timely results.',
    icon: logo,
  },
  {
    title: 'Radiology Services',
    description: 'State-of-the-art radiology services including X-ray, MRI, and CT scans.',
    icon: logo,
  },
  {
    title: 'Pharmacy Services',
    description: 'In-house pharmacy with a wide range of medications and health products.',
    icon: logo,
  },
  {
    title: 'Surgical Services',
    description: 'Advanced surgical services with expert surgeons and modern operating rooms.',
    icon: logo,
  },
  {
    title: 'Rehabilitation Services',
    description: 'Comprehensive rehabilitation services including physiotherapy and occupational therapy.',
    icon: logo,
  },
];

const Services = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center container mx-auto py-12">
        <h1 className="text-5xl font-extrabold text-gray-800 mb-8">Our Services</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="bg-white shadow-lg rounded-lg p-6 text-center">
              <img src={service.icon} alt={service.title} className="mx-auto mb-4" />
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">{service.title}</h2>
              <p className="text-lg text-gray-700">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Services;
