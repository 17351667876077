const Footer=()=>{
    return(<>
          <footer className="bg-blue-800 text-white py-8">
        <div className="container mx-auto px-6 text-center flex  flex-col sm:flex-row justify-between">
          <div className="text-xl font-bold mb-4">National Hospital</div>
         
          <div className="space-x-4">
            <a href="#" className="hover:text-gray-300">Privacy Policy</a>
            <a href="#" className="hover:text-gray-300">Terms of Service</a>
          </div>
        </div>
      </footer>
    </>)
}

export default Footer;