import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import HomePage from './Pages/Homepage';
import ContactUs from './Pages/ContactUs';
import AboutUs from './Pages/AboutUs';
import Treatment from './Pages/Treatment';
import Services from './Pages/Services';
import FreeMedicalCamp from './Pages/FreeMedicalCamp';

function App() {
  return (
    <div className="App">

        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route  path='/contactus' element={<ContactUs />} />
                <Route  path='/aboutus' element={<AboutUs /> } />
                <Route  path='/treatments' element={<Treatment /> } />
                <Route  path='/services' element={<Services /> } />

                <Route  path='/medical-camp' element={<FreeMedicalCamp /> } />

            </Routes>
        </BrowserRouter>
      
    </div>
  );
}

export default App;
